import React from 'react';
import { unified } from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import {
  Headline,
  Text,
  Divider,
  TextList,
  TextListItem,
  TextLink,
} from '@oneaudi/unified-web-components';
import * as prod from 'react/jsx-runtime';

import type { Options } from 'rehype-react';
import type { ReactElement, JSXElementConstructor, ReactNode } from 'react';
import styled from 'styled-components';

const StyledHeadline = styled(Headline)`
  sup {
    color: inherit;
    font-size: 0.583em;
  }
`;

interface Props {
  style: { [key: string]: string };
  href?: string;
  target?: string;
}

const StyledOrderedList = styled(TextList)`
  li::before {
    left: -10px !important;
  }
`;

const defaultOptions = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };
const rehypeReactOptions = {
  ...defaultOptions,
  components: {
    h1(props: React.PropsWithChildren) {
      return (
        <StyledHeadline
          tag="h1"
          variant={{
            type: 'headline',
            order: '1',
            weight: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          {processChildren(props.children)}
        </StyledHeadline>
      );
    },
    h2(props: React.PropsWithChildren) {
      return (
        <StyledHeadline
          tag="h2"
          variant={{
            type: 'headline',
            order: '2',
            weight: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          {processChildren(props.children)}
        </StyledHeadline>
      );
    },
    h3(props: React.PropsWithChildren) {
      return (
        <StyledHeadline
          tag="h3"
          variant={{
            type: 'headline',
            order: '3',
            weight: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          {processChildren(props.children)}
        </StyledHeadline>
      );
    },
    h4(props: React.PropsWithChildren) {
      return (
        <StyledHeadline
          tag="h4"
          variant={{
            type: 'headline',
            order: '4',
            weight: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          {processChildren(props.children)}
        </StyledHeadline>
      );
    },
    h5(props: React.PropsWithChildren) {
      return (
        <StyledHeadline
          tag="h5"
          variant={{
            type: 'headline',
            order: '4',
            weight: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          {processChildren(props.children)}
        </StyledHeadline>
      );
    },
    h6(props: React.PropsWithChildren) {
      return (
        <StyledHeadline
          tag="h6"
          variant={{
            type: 'headline',
            order: '4',
            weight: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          {processChildren(props.children)}
        </StyledHeadline>
      );
    },
    p(props: React.PropsWithChildren) {
      return (
        <Text
          tag="p"
          variant={{
            type: 'copy',
            order: '1',
            style: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          {processChildren(props.children)}
        </Text>
      );
    },
    div(props: React.PropsWithChildren) {
      return <div>{processChildren(props.children)}</div>;
    },
    span(props: React.PropsWithChildren) {
      return (
        <Text
          tag="span"
          variant={{
            type: 'copy',
            order: '1',
            style: 'normal',
          }}
        >
          {processChildren(props.children)}
        </Text>
      );
    },
    ul(props: React.PropsWithChildren) {
      return (
        <TextList
          variant="bullet"
          margin={[0, 0, 'var(--spacing-relative-md)', 'var(--spacing-relative-md)']}
        >
          {processChildren(props.children)}
        </TextList>
      );
    },
    ol(props: React.PropsWithChildren) {
      return (
        <StyledOrderedList
          variant="decimal"
          margin={[0, 0, 'var(--spacing-relative-md)', 'var(--spacing-relative-md)']}
        >
          {processChildren(props.children)}
        </StyledOrderedList>
      );
    },
    li(props: React.PropsWithChildren) {
      return <TextListItem>{processChildren(props.children)}</TextListItem>;
    },
    hr() {
      return (
        <Divider margin={['var(--spacing-relative-2xl)', 0, 'var(--spacing-relative-2xl)', 0]} />
      );
    },
    blockquote({ children, ...props }: React.PropsWithChildren) {
      return (
        <blockquote {...props}>
          <Text
            tag="p"
            variant={{
              type: 'copy',
              order: '1',
              style: 'normal',
            }}
          >
            {processChildren(children)}
          </Text>
        </blockquote>
      );
    },
    pre(props: React.PropsWithChildren) {
      return (
        <Text
          tag="span"
          variant={{
            type: 'copy',
            order: '1',
            style: 'normal',
          }}
          margin={[0, 0, 'var(--spacing-relative-md)', 0]}
        >
          <pre>{processChildren(props.children)}</pre>
        </Text>
      );
    },
    a({ children, ...props }: React.PropsWithChildren) {
      const { href, target } = props as Props;
      return (
        <TextLink href={href as string} size="md" variant="inline" target={target}>
          {processChildren(children)}
        </TextLink>
      );
    },
    strong({ children }: React.PropsWithChildren) {
      return (
        <Text
          tag="span"
          variant={{
            type: 'copy',
            order: '1',
            style: 'bold',
          }}
        >
          {processChildren(children)}
        </Text>
      );
    },
  },
};
const processChildren = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: string | ReactNode | ReactElement<any, string | JSXElementConstructor<any>>[],
) => {
  if (typeof children === 'string') {
    return processChild(children);
  }
  if (Array.isArray(children)) {
    return children.map((child) => processChild(child as unknown as string));
  }
  return children;
};
const processChild = (value: string) => {
  if (typeof value === 'string' && value.match(/({ft_[a-z0-9-_]*})/gi)) {
    return renderTextWithFootnotesReferencesV2(value);
  }
  return value;
};

export const convertHtmlToReact = (html: string) => {
  const file = unified()
    .use(rehypeParse, {
      fragment: true,
    })
    .use(rehypeReact, rehypeReactOptions as unknown as Options)
    .processSync(html);

  return file.result;
};
